.inCard {
	background-color: #f1f1f1 !important;
	border-radius: 15px;
	position: relative;
}

.totalCardsBottom_left {
	color: white;
	background-image: linear-gradient(
		to left,
		var(--my-blue),
		var(--my-purple)
	);
	border-top: 3px solid #c7c7c7;
	border-radius: 0 0 15px 15px !important;
}

.cardIconLeft {
	position: absolute;
	height: 80px;
	top: -40px;
	right: 3rem;
}

.totalCardsBottom_right {
	color: white;
	background-image: linear-gradient(
		to right,
		var(--my-blue),
		var(--my-purple)
	);
	border-top: 3px solid #c7c7c7;
	border-radius: 0 0 15px 15px !important;
}

.cardIconRight {
	position: absolute;
	height: 80px;
	top: -40px;
	left: 3rem;
}

.dailyCardsBottom {
	color: white;
	background-color: var(--my-green);
	border-top: 3px solid #c7c7c7;
	border-radius: 0 0 15px 15px !important;
}

.balanceCard {
	color: white;
	background-image: linear-gradient(
		to left,
		var(--my-blue),
		var(--my-purple)
	);
	border-radius: 15px;
	border-image-slice: 1;
	border-image-source: linear-gradient(
		to left,
		var(--my-blue),
		var(--my-purple)
	);
	position: relative;
}

.cardIconCenter {
	position: absolute;
	height: 80px;
	margin-left: -40px;
	top: -40px;
	left: 50%;
}

.balanceCardsBottom {
	color: var(--my-purple);
	background-color: #f1f1f1 !important;
	border: 2px solid #f1f1f1;
	border-top: 3px solid #c7c7c7;
	border-radius: 0 0 15px 15px !important;
	box-shadow: 0px 3px 0px 3px #f1f1f1;
}

.personCard {
	background-color: transparent !important;
	border-color: transparent !important;
	border-radius: 15px;
}

.personCardBottom {
	box-shadow: 0 30px 90px -20px rgb(0 0 0 / 30%),
		0 0 1px 1px rgba(255, 255, 255, 0.05);
	color: white;
	background-color: var(--my-blue);
	border-radius: 0 0 15px 15px !important;
	position: relative;
}

.personCardIconRight {
	position: absolute;
	height: 80px;
	top: -40px;
	left: 1rem;
}

.personCardIconLeft {
	position: absolute;
	height: 80px;
	top: -40px;
	right: 1rem;
}

@media (max-width: 575.98px) {
	.personCardBottom {
		box-shadow: none;
	}
}
