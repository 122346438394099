.contentsBody::-webkit-scrollbar {
	width: 3px;
}

.contentsBody::-webkit-scrollbar-track {
	background: #ffffff;
	width: 3px;
}

.contentsBody::-webkit-scrollbar-thumb {
	background: #888;
}

.contentsBody::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.dashboardPage {
	background-image: url('../img/dashboard.jpg');
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 100vh;
}

.contentsCard {
	background-image: var(--background);
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 15px;
	border-color: transparent;
}

.alertBox {
	border-radius: 15px;
}

.summaryEmail {
	word-wrap: break-word !important;
	word-break: break-word !important;
	max-width: 150px;
	margin: auto;
}

.myPagination {
	color: white;
}

.myPagination li span {
	color: var(--my-navy) !important;
	padding: 0.375rem 0.6rem !important;
	background-color: unset;
	border: 1px solid var(--my-navy) !important;
}

.myPagination li span:hover {
	cursor: pointer;
	color: white !important;
	background-color: var(--my-navy);
	border-color: var(--my-navy);
}

.myActive {
	background-color: var(--my-purple);
	border-color: var(--my-purple) !important;
}

.myActive span:hover {
	background-color: var(--my-purple) !important;
	border-color: var(--my-purple);
}

@media (max-width: 991.98px) {
	.summaryEmail {
		max-width: unset;
	}
}

.notifBadge {
	background-color: #fa3e3e;
	color: #ffffff;
	border-radius: 41px;
	padding: 1px 5px;
	font-size: 10px;
	position: absolute;
	top: 0;
	right: -22px;
}

.x_mass {
	background-color: #006837;
}

.nowrooz {
	background: linear-gradient(
		90deg,
		rgba(2, 0, 36, 1) 0%,
		rgba(25, 66, 136, 1) 50%,
		rgba(2, 0, 36, 1) 100%
	);
}
.festival-text {
	color: #fcc018;
	font-size: 55px;
}

.eidToEid {
	background-image: url(../img/festival/EidToEid/bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
}

.eidToEidShadow {
	filter: drop-shadow(0 0 10px #000000);
}

.gala {
	background-image: url(../img/festival/gala/bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
}

.newSeason {
	background-image: url(../img/festival/newSeason/bgDesktop.webp);
	background-repeat: no-repeat;
	background-size: cover;
}

.trueImage {
	position: absolute;
	left: 60px;
	top: -5px;
}

@media (max-width: 991.98px) {
	.trueImage {
		position: absolute;
		left: 27px;
		top: -5px;
	}
	.gala {
		background-image: url(../img/festival/gala/bgMob.jpg);
	}

	.newSeason {
		background-image: url(../img/festival/newSeason/bgMobile.webp);
	}
}

.contractNavs {
	opacity: 0.4;
	filter: grayscale(100%);
}

.contractNavs.compelte {
	opacity: 1;
	filter: grayscale(0);
}

.contractNavs.active {
	opacity: 1;
	filter: drop-shadow(0 0 10px #4286f4);
}

.p3FormError {
	color: #842029;
	background-color: #f8d7da !important;
}

.uploadBoxID {
	border-radius: 10px;
	max-width: 100%;
	height: 200px;
	cursor: pointer;
	object-fit: cover;
}

.uploadBoxPass {
	border-radius: 10px;
	max-width: 100%;
	height: 400px;
	cursor: pointer;
	object-fit: cover;
}

.dropArea:hover {
	color: #afb2b4;
	border: 2px dashed #afb2b4;
	background-color: #ebebeb;
	cursor: pointer;
}

.isDragging {
	background-color: #d1c4e962;
}

.dropArea i {
	font-size: 60px;
}

.dropArea span:hover {
	cursor: pointer;
}
