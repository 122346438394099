.bg {
	background-color: var(--background) !important;
	transition: all 0.5s;
}

#myNav li a::after {
	content: url('../img/circle.png');
	margin-left: 10px;
	margin-right: 10px;
}

#myNav li:last-child a::after,
.btnLink::after {
	content: none !important;
}

.dropDown {
	background-color: var(--background) !important;
	border: 1px solid var(--border) !important;
}

.dropDownItem {
	color: var(--link) !important;
}

.dropDownItem:hover {
	background-color: unset !important;
	color: var(--link-hover) !important;
}

.dorpDownDivider {
	border-top: 1px solid var(--border) !important;
}

.btn-nav {
	min-width: 95px !important;
}

@media (max-width: 1199.98px) {
	#myNav li a::after {
		content: none !important;
	}
}

@media (max-width: 1199.98px) {
	#myNav li a::after {
		content: none !important;
	}
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
	#myNav li a::after {
		margin-left: 5px;
		margin-right: 5px;
	}
}
