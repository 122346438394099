.blog_header {
	background-image: url('../img/blogs/header.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(23, 23, 23);
}

.blog_image {
	border-radius: 0 0 20px 20px;
}

.carousel_carContainer {
	background-color: transparent !important;
	border: 1px solid rgb(164, 164, 164);
	border-radius: 5px;
	padding-left: 12px;
	padding-right: 12px;
}

.carousel_images {
	display: block !important;
	margin-left: auto;
	margin-right: auto;
}

#benefits_1 {
	background-image: url('../img/blogs/benefits/cta1.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 600px;
	color: white;
}
#benefits_2 {
	background-image: url('../img/blogs/benefits/cta2.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 600px;
	color: white;
}
#benefits_3 {
	background-image: url('../img/blogs/benefits/cta3.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 600px;
	color: white;
}
