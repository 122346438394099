@font-face {
	font-family: 'Segoe UI Regular';
	font-style: normal;
	font-weight: normal;
	src: local('Segoe UI Regular'), url('../fonts/Segoe UI.woff') format('woff');
}
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
	src: local('Roboto'), url('../fonts/Roboto-Regular.ttf') format('ttf');
}

:root {
	--font-color: #656565;
	--menu-color: #005fc2;
	--my-purple: #a441ff;
	--my-blue: #29abe2;
	--my-navy: #1e2850;
	--my-green: #00c89b;
	--my-yellow: #ffc107;
	--background: url(../img/back.jpg);
	--dashboard-background: url(../img/dashboard.jpg);
}
