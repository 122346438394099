.dropArea {
	color: #ced4da;
	border: 2px dashed #ced4da;
}

.logo {
	border-radius: 50%;
	width: 160px;
	height: 160px;
	cursor: pointer;
	object-fit: cover;
}

.featured {
	border-radius: 25px;
	width: 300px;
	height: 200px;
	cursor: pointer;
	object-fit: cover;
}

.banner {
	border-radius: 25px;
	max-width: 100%;
	height: 200px;
	cursor: pointer;
	object-fit: cover;
}

.item {
	border-radius: 10px;
	/* width: 400px; */
	max-width: 90%;
	height: 200px;
	cursor: pointer;
	object-fit: cover;
}

.dropArea:hover {
	color: #afb2b4;
	border: 2px dashed #afb2b4;
	background-color: #ebebeb;
	cursor: pointer;
}

.isDragging {
	background-color: #d1c4e962;
}

.dropArea i {
	font-size: 60px;
}

.dropArea span:hover {
	cursor: pointer;
}
