.sidebarBody::-webkit-scrollbar {
	width: 3px;
}

.sidebarBody::-webkit-scrollbar-track {
	background: #ffffff;
	width: 3px;
}

.sidebarBody::-webkit-scrollbar-thumb {
	background: #888;
}

.sidebarBody::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.sidebar {
	background: #f4f4f499 !important;
	border-radius: 15px !important;
	border-color: transparent !important;
	/* box-shadow: -12px 11px 31px #ffffff52, 13px -11px 31px #f4f4f461; */
}

.myNavLink {
	display: block;
	padding: 0.75rem 1rem;
	text-decoration: none;
	color: var(--menu-color);
	font-weight: 500;
}

.myNavLink:hover {
	cursor: pointer;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 2rem;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	border: 1px solid rgba(255, 255, 255, 0.3);
}

.myNavLink:not(:hover) {
	border-radius: 2rem;
}

.accordion {
	cursor: pointer;
	width: 100%;
	border: none;
	text-align: left;
	outline: none;
	transition: 0.4s;
}

.iconOpen {
	transform: rotateZ(-180deg);
	transform-origin: center;
	transition: 0.4s;
}

.iconClose {
	transform: rotateZ(0deg);
	transform-origin: center;
	transition: 0.4s;
}

.accordionPanel {
	padding-left: 20px;
	font-size: 14px;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-out;
}
