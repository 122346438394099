body::-webkit-scrollbar {
	width: 7px;
}
body::-webkit-scrollbar-track {
	background: #f1f1f1;
}
body::-webkit-scrollbar-thumb {
	background: #888;
}
body::-webkit-scrollbar-thumb:hover {
	background: #555;
}

html,
body {
	height: 100%;
}

body {
	font-family: 'Roboto';
	background-image: var(--background);
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	overflow-x: hidden;
	margin: 0;
	color: var(--font-color);
}

.bg-warning-subtle {
	background-color: #fff3cdcb !important;
}

.fs-7 {
	font-size: 0.75rem !important;
}

.fs-8 {
	font-size: 0.5rem !important;
}

.fs-9 {
	font-size: 0.25rem !important;
}

.centerAll {
	display: flex;
	align-items: center;
	justify-content: center;
}

a,
a:hover {
	text-decoration: none;
}

.defaultLink {
	text-decoration: none;
}

.myTable {
	color: var(--font-color);
}

.mmiTable {
	font-size: 13px;
}

.mmiTable > :not(caption) > * > * {
	padding: 0 0.25rem !important;
}

.bg-gray {
	background-color: #d8d8d8 !important;
}

.text-navy {
	color: var(--my-navy) !important;
}
.text-purple {
	color: var(--my-purple) !important;
}
.text-green {
	color: var(--my-green) !important;
}
.text-blue {
	color: var(--my-blue) !important;
}
.text-yellow {
	color: var(--my-yellow) !important;
}

.borderRight {
	border-right: #6565654b solid 1px;
}

@media (max-width: 767.98px) {
	.borderRight {
		border-right: none;
	}
	.myTable,
	.mmiTable {
		font-size: 12px !important;
	}
}

.myImages {
	border-radius: 15px;
}

.bevel {
	border-top: 2px solid lightgrey;
	border-bottom: 2px solid grey;
	border-left: 2px solid lightgrey;
	border-right: 2px solid grey;
	/* border: 1px outset grey; */
}

.headerImageWrapper {
	position: relative;
}

.headerImage {
	display: block;
	position: relative;
	z-index: 2;
}

.headerImageShadow {
	bottom: -5px;
	filter: blur(18px);
	height: 100%;
	left: 0;
	position: absolute;
	opacity: 0.7;
	transform: scale(1.01);
	z-index: 1;
}

.myImages.blue {
	/* box-shadow: 0 20px 60px -20px #a4b3f4, 0 0 1px 1px rgb(255 255 255 / 5%); */
	-webkit-box-reflect: below 0px linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
	-webkit-box-reflect: right 0px linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
}

.myImages.orange {
	box-shadow: 0 20px 60px -20px rgb(243 203 191), 0 0 1px 1px rgb(255 255 255 / 5%);
}

.myCards {
	box-shadow: 0px 0px 20px 16px rgb(126 124 124 / 11%);
	border-color: transparent;
	border-radius: 15px;
}

.myActiveNavLink {
	background-color: #ffffff98;
	border-radius: 0 2rem 2rem 0;
}

.accordion-button:hover {
	background: rgba(255, 255, 255, 0.2) !important;
	border-radius: 2rem !important;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	border: 1px solid rgba(255, 255, 255, 0.3);
}

.loginPage {
	background-image: url('../img/loginBack.jpg');
	background-color: #010b17;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 100vh;
}

.coin-logo {
	height: 24px;
	width: 24px;
	border-radius: 12px;
}

.mmpCard {
	position: relative;
	text-align: center;
	/* font-size: 22px !important; */
	border-radius: 15px;
	box-shadow: -1px 1px 14px 4px rgb(0 0 0 / 30%), 0 0 1px 1px rgb(0 0 0 / 5%);
}

.mmpCardImage {
	position: relative;
	overflow: hidden;
}

.mmpCardImage:after {
	animation: shine 6s ease-in-out infinite;
	animation-fill-mode: forwards;
	content: '';
	position: absolute;
	top: -200%;
	left: -200%;
	width: 150%;
	height: 150%;
	opacity: 0;
	transform: rotate(30deg);

	background: rgba(255, 255, 255, 0.13);
	background: linear-gradient(
		to right,
		rgba(255, 255, 255, 0.13) 0%,
		rgba(255, 255, 255, 0.13) 77%,
		rgba(255, 255, 255, 0.5) 92%,
		rgba(255, 255, 255, 0) 100%
	);
}

@keyframes shine {
	10% {
		opacity: 1;
		top: -30%;
		left: -30%;
		transition-property: left, top, opacity;
		transition-duration: 0.7s, 3s, 0.15s;
		transition-timing-function: ease;
	}
	100% {
		opacity: 0;
		top: -30%;
		left: -30%;
		transition-property: left, top, opacity;
	}
}

/* animation */

@keyframes slide {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
}

@media (min-width: 51.98px) and (max-width: 309.98px) {
	.mmpCard {
		font-size: 13px !important;
	}
}
@media (min-width: 310px) and (max-width: 575.98px) {
	.mmpCard {
		font-size: 14px !important;
	}
}
@media (min-width: 576px) and (max-width: 767.98px) {
	.mmpCard {
		font-size: 20px !important;
	}
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
	.mmpCard {
		font-size: 13px !important;
	}
}

.loginCard {
	background: #f4f4f499;
	box-shadow: -12px 11px 31px #ffffff52, 13px -11px 31px #f4f4f461;
	border-radius: 30px;
	border-color: transparent;
}

.innerTabPanels {
	border: 1px solid #dee2e6;
	border-top-color: transparent;
	border-radius: 0 0 5px 5px;
}

.my-footer {
	background-color: var(--my-navy) !important;
	margin-top: auto;
	color: white;
}

.my-footer-bottom {
	background-color: var(--my-purple) !important;
	color: white;
}

.socialSection a {
	color: white !important;
}

.socialIcons {
	width: 35px;
	height: 35px;
	border-radius: 25px;
	font-size: 20px;
	border: 1px white solid;
	text-decoration: none !important;
	color: white !important;
	display: flex !important;
	justify-content: center;
	align-items: center;
}

.socialIcons:hover {
	color: var(--my-green) !important;
	border-color: white;
	background: white;
}

.gopro2 {
	background-image: linear-gradient(
			to bottom,
			rgba(245, 246, 252, 0.52),
			rgba(164, 164, 164, 0.73)
		),
		url(../img/gopro.jpg);
	border: 1px solid #dfdcdc;
}

.gopro3 {
	background-image: radial-gradient(
			circle,
			rgba(44, 44, 44, 0) 0%,
			rgba(29, 29, 29, 0.5) 50%,
			rgba(6, 6, 6, 1) 100%
		),
		url(../img/gopro3.png),
		radial-gradient(
			circle,
			rgba(44, 44, 44, 1) 0%,
			rgba(29, 29, 29, 1) 50%,
			rgba(6, 6, 6, 1) 100%
		);
	border: 1px solid rgba(44, 44, 44, 1) 0%;
}

.gopro4 {
	background-image: linear-gradient(to bottom, #3b0092, #4441cc);
	border: 1px solid #dfdcdc;
}

.gopro4 .innerText {
	color: #e9b353;
}

.blackFriday {
	background-image: url(../img/festival/bg.jpg);
	background-size: cover;
	background-repeat: no-repeat;
}

.flagship {
	background-image: url(../img/flagship/bg.jpg);
	background-size: cover;
	background-repeat: no-repeat;
}

.blur {
	filter: blur(2px);
}

.contestResult {
	filter: drop-shadow(0px 2px 10px #ffffffb4);
}

.prices {
	background-color: var(--my-blue) !important;
	color: white;
}
