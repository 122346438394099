.navbar {
	background-color: #e9ecef;
	border-bottom: 3px solid;
	border-image-slice: 1;
	border-image-source: linear-gradient(
		to left,
		var(--my-blue),
		var(--my-purple)
	);
}

.navbar .navbar-toggler {
	top: 0.3rem !important;
	right: 0.5rem !important;
	scale: 0.8;
}
