#hero_1 {
	background-image: url('../img/home/hero/1.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 600px;
	color: #4b4b4b;
}
#hero_2 {
	background-image: url('../img/home/hero/2.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 600px;
	color: #4b4b4b;
}
#hero_3 {
	background-image: url('../img/home/hero/3.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 600px;
	color: #4b4b4b;
}

.advantages {
	background-color: #343434;
	color: white;
}

.adv_cards {
	color: white;
	background-color: #662d91;
	border-radius: 15px;
	min-height: 350px;
	position: relative;
}

.adv_cards_btn {
	position: absolute;
	bottom: -18px;
}

.marketmaker,
.mmi {
	background-image: url('../img/home/bg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	color: white;
}

.benefit_cards {
	color: white;
	background-color: #503ae7;
}

#mmp_1 {
	background-image: url('../img/home/mmp/1.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 520px;
	color: white;
}
#mmp_2 {
	background-image: url('../img/home/mmp/2.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 520px;
	color: white;
}
#mmp_3 {
	background-image: url('../img/home/mmp/3.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 520px;
	color: white;
}

.permissions {
	background-image: url('../img/home/map.png');
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 600px;
}

@media (max-width: 1199.98px) {
	#hero_1,
	#hero_2,
	#hero_3 {
		min-height: 520px;
		background-image: none;
	}
	#mmp_1,
	#mmp_2,
	#mmp_3 {
		min-height: 450px;
	}

	.adv_cards {
		min-height: 226px;
	}
}
