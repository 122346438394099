.fade5 {
	opacity: 0.5;
}

.myCard {
	color: white;
}

.darkCard {
	color: white;
	background-color: rgb(75, 75, 75) !important;
	border-color: rgb(75, 75, 75) !important;
}

.lightCard {
	color: black;
	background-color: white !important;
	border-color: white !important;
}

.icon {
	font-size: 3.5em;
}

.iconLG {
	font-size: 4.68em;
}

.green {
	color: var(--flat-color-one);
}

.greenBG {
	background-color: var(--flat-color-one) !important;
	border-color: var(--flat-color-one) !important;
}

.purple {
	color: var(--flat-color-two);
}

.purpleBG {
	background-color: var(--flat-color-two) !important;
	border-color: var(--flat-color-two) !important;
}

.cyan {
	color: var(--flat-color-three);
}

.cyanBG {
	background-color: var(--flat-color-three) !important;
	border-color: var(--flat-color-three) !important;
}

.orange {
	color: var(--flat-color-four);
}

.orangeBG {
	background-color: var(--flat-color-four) !important;
	border-color: var(--flat-color-four) !important;
}

.lime {
	color: var(--flat-color-five);
}

.limeBG {
	background-color: var(--flat-color-five) !important;
	border-color: var(--flat-color-five) !important;
}

.pink {
	color: var(--flat-color-six);
}

.pinkBG {
	background-color: var(--flat-color-six) !important;
	border-color: var(--flat-color-six) !important;
}

.blue {
	color: var(--flat-color-seven);
}

.blueBG {
	background-color: var(--flat-color-seven) !important;
	border-color: var(--flat-color-seven) !important;
}

.gray {
	color: var(--flat-color-eight);
}

.grayBG {
	background-color: var(--flat-color-eight) !important;
	border-color: var(--flat-color-eight) !important;
}

.myPagination {
	color: white;
}

.myPagination li span {
	color: var(--my-navy) !important;
	padding: 0.375rem 0.6rem !important;
	background-color: unset;
	border: 1px solid var(--my-navy) !important;
}

.myPagination li span:hover {
	cursor: pointer;
	color: white !important;
	background-color: var(--my-navy);
	border-color: var(--my-navy);
}

.myActive {
	background-color: var(--my-purple);
	border-color: var(--my-purple) !important;
}

.myActive span:hover {
	background-color: var(--my-purple) !important;
	border-color: var(--my-purple);
}

.myListGroupItem {
	color: var(--my-navy) !important;
	border-color: var(--my-navy) !important;
}

.myListGroupItemActive {
	background-color: var(--my-purple) !important;
	border-color: var(--my-purple) !important;
}

.myListGroupItem:hover {
	cursor: pointer;
	color: white !important;
	background-color: var(--my-navy);
	border-color: var(--my-navy);
}

.myListGroupItem:hover img,
.myListGroupItemActive img {
	background-color: white;
	border-radius: 50%;
	padding: 2px;
}

.walletCards {
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 12px !important;
	border-color: transparent !important;
	color: white;
}

.walletCards hr {
	border-color: rgb(255, 255, 255) !important;
	border-top-width: 2px;
}

.walletCards.currentDeposit {
	background-image: linear-gradient(
		to bottom right,
		#ff4fbb,
		#b901b9,
		#78007a
	);
}

.walletCards.activation {
	background-image: linear-gradient(
		to bottom right,
		#5bbaf0,
		#0061a2,
		#160d4e
	);
}

.walletCards.discount {
	background-image: linear-gradient(
		to bottom right,
		#00f0fc,
		#26a7e1,
		#006fbb
	);
}

.walletCards.usdt {
	background-image: linear-gradient(
		to bottom right,
		#a5c69b,
		#38b34b,
		#006d39
	);
}

.walletCards.token {
	background-image: linear-gradient(
		to bottom right,
		#ff8676,
		#fb0102,
		#870000
	);
}

.walletCards.forex {
	background-image: linear-gradient(
		to bottom right,
		#fadc26,
		#ff901d,
		#ee5e21
	);
}

.walletCards.nft {
	background-image: linear-gradient(
		to bottom right,
		#bb9266,
		#875d35,
		#412108
	);
}

.walletCards.defi {
	background-image: linear-gradient(
		to bottom right,
		#d25f98,
		#6b003f,
		#30001f
	);
}

.walletImageWrapper {
	position: relative;
}

.walletImage {
	display: block;
	position: relative;
	z-index: 2;
}

.walletImageShadow {
	bottom: 0;
	filter: blur(14px);
	height: 100%;
	left: 0;
	position: absolute;
	opacity: 0.7;
	transform: scale(1.01);
	z-index: 1;
}
